import {HostListener, Injectable} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  // Observable string sources
  private reminderCallSource = new Subject<any>();
  private reminderListCallSource = new Subject<any>();
  private exportInvoiceReminderListCallSource = new Subject<any>();
  private freightForwarderReminderListCallSource = new Subject<any>();
  private workboardReminderListCallSource = new Subject<any>();

  // Observable string streams
  reminderMethodCalled$ = this.reminderCallSource.asObservable();
  reminderListMethodCalled$ = this.reminderListCallSource.asObservable();
  exportInvoiceReminderListMethodCalled$ = this.exportInvoiceReminderListCallSource.asObservable();
  freightForwarderReminderListMethodCalled$ = this.freightForwarderReminderListCallSource.asObservable();
  workboardReminderListMethodCalled$ = this.workboardReminderListCallSource.asObservable();

  private form_is_dirty = false;

  // Service message commands
  callReminderMethod(reminder_obj) {
    this.reminderCallSource.next(reminder_obj);
  }

  callReminderListMethod(obj) {
    this.reminderListCallSource.next(obj);
  }

  callExportInvoiceReminderListMethod(obj) {
    this.exportInvoiceReminderListCallSource.next(obj);
  }

  callFreightForwarderReminderListMethod(obj) {
    this.freightForwarderReminderListCallSource.next(obj);
  }

  callWorkboardReminderListMethod(obj) {
    this.workboardReminderListCallSource.next(obj);
  }


  // tslint:disable-next-line: member-ordering
  private readonly _form_dirty_status = new BehaviorSubject<boolean>(false);

  // tslint:disable-next-line: member-ordering
  readonly form_dirty_status$ = this._form_dirty_status.asObservable();

  get form_dirty_status(): boolean {
    return this._form_dirty_status.getValue();
  }

  // @HostListener('window:beforeunload', ['$event'])
  set form_dirty_status(val: boolean) {
    // $event.returnValue =true;
    this._form_dirty_status.next(val);
  }


  // tslint:disable-next-line: member-ordering
  private readonly _check_form_dirty = new BehaviorSubject<boolean>(false);

  // tslint:disable-next-line: member-ordering
  readonly _check_form_dirty$ = this._check_form_dirty.asObservable();

  get check_form_dirty(): boolean {
    return this._check_form_dirty.getValue();
  }

  set check_form_dirty(val: boolean) {
    this._check_form_dirty.next(val);
  }

  constructor() { }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this._form_dirty_status) {
      $event.returnValue =true;
    }
  }
}


