import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpClient } from "@angular/common/http";
import { HttpInterceptor } from "@angular/common/http";

import 'rxjs/add/operator/catch';
import { AuthService } from '../auth.service';
import { filter, take, switchMap } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router } from "@angular/router";
import {Observable} from "rxjs/observable";
import {BehaviorSubject} from "rxjs/BehaviorSubject";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    urlsToNotUse: Array<string>;
    apiCallsNotToAddContentType: Array<string>;

    constructor(public http: HttpClient, private auth: AuthService, private router:Router) {
        this.urlsToNotUse = [
            'domain.com.au/.+'
        ];

        this.apiCallsNotToAddContentType = ['upload/file/', 'upload/filePdf/', 'upload/fileReview/', 'upload/files/', 'upload/decodeFiles/',
            'upload/remove-file/', 'upload/profile-image/', 'upload/gallery-image/', 'upload/product-image/','file/common-file-upload',
            'file/rental-file-upload', 'file/expense-file-upload', 'file/pdf-file-upload','file/images_download_as_zip','file/get-file-download',
          'file/mortgage-file-upload','file/profile-media-upload','file/split-mortgage-file-upload'];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('authToken');

        if (token && this.isValidRequestForInterceptor(req.url)) {
            if (this.addContentType(req.url)) {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                    }
                });
            } else {
                req = req.clone({
                    setHeaders: {
                        // 'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                    }
                });
            }

        }

        return next.handle(req).catch(err => {
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if (
                req.url.includes("refreshtoken") ||
                req.url.includes("login")
            ) {
                // We do another check to see if refresh token failed
                // In this case we want to logout user and to redirect it to login page
                if (req.url.includes("refreshtoken")) {
                    this.auth.signOut();
                }
                return Observable.throw(err);
            }

            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (err.status !== 401) {
                return Observable.throw(err);
            }

            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // – which means the new token is ready and we can retry the request again

                return this.refreshTokenSubject.pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addAuthenticationToken(req));
                    })
                );
            } else {

                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                // Call auth.refreshAccessToken(this is an Observable that will be returned)

                return this.auth.refreshAccessToken().pipe(
                        switchMap((token: any) => {
                            //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            localStorage.setItem('authToken', token['token']);
                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(token);
                            return next.handle(this.addAuthenticationToken(req));
                        }))
                    .catch((err: any) => {
                        this.refreshTokenInProgress = false;
                        this.auth.signOut();
                        localStorage.clear();
                        this.router.navigate(['/']);
                        return Observable.throw(err);
                    });
            }
        });
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = localStorage.getItem('authToken');

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            }
        });
    }

    private isValidRequestForInterceptor(requestUrl: string): boolean {
        let positionIndicator: string = 'api.';
        let position = requestUrl.indexOf(positionIndicator);
        if (position > 0) {
            let destination: string = requestUrl.substr(position + positionIndicator.length);
            for (let address of this.urlsToNotUse) {
                if (new RegExp(address).test(destination)) {
                    return false;
                }
            }
        }
        return true;
    }

    private addContentType(requestUrl: string): boolean {
        for (let address of this.apiCallsNotToAddContentType) {
            if (requestUrl.indexOf(address) !== -1) {
                return false;
            }
        }
        return true;
    }
}
