<nav class="navbar fixed-top">
  <div id="nav_right_full" class="display-none d-block">
    <div class="d-flex align-items-center navbar-left justify-content-between pl-3 pr-3" >
      <img src="assets/img/logo_white.png" height="35px">
      <div class="ml-4">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
           href="javascript:;" class="menu-button d-none d-md-block">
          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg> {{" "}}
          <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>
        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
           class="menu-button-mobile d-xs-block d-sm-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div id="nav_right_half" class="display-none">
    <div class="d-flex align-items-center navbar-left collapse-nav display-none" >
      <div class="logo-div">
        <img src="assets/img/logo_half.png" style="height: 22px;padding: 0 7px;">
      </div>
      <div class="menu-collaps ml-4">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
           href="javascript:;" class="menu-button d-none d-md-block">
          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg> {{" "}}
          <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>
        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
           class="menu-button-mobile d-xs-block d-sm-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div class="navbar-right-section d-flex justify-content-between">
    <div class="top-nav-menu align-self-center">
      <ul class="list-unstyled mb-0 d-inline-flex">
        <li>My Docs<li>
        <li>My Supplies<li>
        <li>My To Do<li>
        <li>New Development<li>
        <li>Contact Us<li>
      </ul>
    </div>
    <div class="">
      <div class="header-icons d-inline-block align-middle">

        <div class="d-none d-md-inline-block align-text-bottom mr-1">
          <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
            <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
                   (change)="onDarkModeChange($event)">
            <label class="custom-control-label" for="darkModeSwitch"></label>
          </div>
        </div>

        <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" >
          <i  class="glyph-icon simple-icon-settings"></i>
        </button>

        <div class="position-relative d-inline-block">

          <div dropdown>
            <button id="button-basic" dropdownToggle type="button" id="notificationButton"
                    class="header-icon btn btn-empty">
              <i class="simple-icon-bell"></i>
              <span class="count">3</span>
            </button>
            <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
                 id="notificationDropdown">
              <perfect-scrollbar>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="javascript:;">
                    <img src="/assets/img/profiles/l-2.jpg" alt="Notification Image"
                         class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                  <div class="pl-3">
                    <a href="javascript:;">
                      <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                      <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-1.jpg" alt="Notification Image"
                         class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                  <div class="pl-3">
                    <a href="javascript:;">
                      <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                      <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-2.jpg" alt="Notification Image"
                         class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                  <div class="pl-3">
                    <a href="javascript:;">
                      <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                      <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-row mb-3 pb-3 ">
                  <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-3.jpg" alt="Notification Image"
                         class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                  <div class="pl-3">
                    <a href="javascript:;">
                      <p class="font-weight-medium mb-1">3 items just added to wish list by a user!
                      </p>
                      <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>

        <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
                (click)="fullScreenClick()">
          <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
          <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
        </button>

      </div>

      <div class="user d-inline-block" dropdown>
        <button class="btn btn-empty p-0" type="button" dropdownToggle>
          <div class="user-topbar d-flex justify-content-between align-items-center">
            <img alt="Profile Picture" src="/assets/img/profiles/l-1.jpg" />
            <div>
              <h4 class="fs-md">Guy Hukwing</h4>
              <h5 class="fs-sm">guy@gmail.com</h5>
            </div>
            <i style="font-size: 14px" class="glyph-icon simple-icon-arrow-down float-right"></i>
          </div>
        </button>

        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer">Account</a>
          <a class="dropdown-item c-pointer">Features</a>
          <a class="dropdown-item c-pointer">History</a>
          <a class="dropdown-item c-pointer">Support</a>
          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
        </div>
      </div>

    </div>
  </div>

</nav>
