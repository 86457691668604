import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/global.service";
import { of } from "rxjs";
import { map } from "rxjs/operators";

declare var gapi: any;
@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  auth: any = {};
  constructor(
      private http: HttpClient,
      private globalService: GlobalService
  ) {
    this.initClient();
  }

  initClient() {

    // gapi.load('client', () => {
    //
    //   gapi.client.init({
    //     apiKey: 'AIzaSyA4XMFqFInqHbPbqVhGn57sfGS7bachUyI',
    //     clientId: '301673974165-c6smgbavkhgcqcrrjfsm8rmmsh126mrn.apps.googleusercontent.com',
    //     discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    //     scope: 'https://www.googleapis.com/auth/calendar'
    //   });
    //   gapi.client.load('calendar', 'v3', () => { });
    //
    //   // var auth =  gapi.auth2.getAuthInstance();
    //   this.auth = gapi.auth2.getAuthInstance();
    //   // console.log('isSignedIn',auth.isSignedIn);
    //   // if(auth.isSignedIn){
    //   //   this.loggedUser.image=auth.signIn().wt.SJ;
    //   //   this.loggedUser.name=auth.signIn().wt.Ad;
    //   // }
    // });

  }
  getProperty(pageSize: number = 10, currentPage: number = 1, search: string = '', orderBy: string = '') {
    var params = '?pageSize=' + pageSize + '&currentPage=' + currentPage + '&search=' + search + '&orderBy=' + orderBy;
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-list' + params, this.globalService.getHttpOptions());
  }

  sortOrderProperty(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/sort-property-order', data, this.globalService.getHttpOptions());
  }

  getSnapshotValidation(property_ref: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/snapshot/get-property-snapshot-validation-details?property_ref=' + property_ref, this.globalService.getHttpOptions());
  }

  countProppelLearningArticleView(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/learning-article/get-learning-article-list', data, this.globalService.getHttpOptions());
  }
}
