<div class="row " style="height: auto!important;">
  <div class="col-12  mx-auto my-auto" >

    <div class="card ">
      <div class="cl-12 col-lg-12 col-md-12">
        <div class="" align="center">
          <img src="../../../assets/img/proppel_logo_aus_beta.png" style="width: 200px" class="mb-2 mt-4">
          <br/>
          <h1> Terms of Use</h1>
        </div>
        <hr/>
        <h1>Welcome to Proppel - The Portal for Australian Property Owners</h1>
        <p>
          The  User  acknowledges  and  agrees  that  Proppel  Pty  Ltd  is  providing  a  product  (a  tool)  for self-management  of  the  Owner’s  properties  and  is  not  in  any  manner  whatsoever  managing the Owner’s property and is not and will not be engaged as the property agent or a resident letting agent for the Owner.
        </p>
        <p>
          These  terms  and  conditions  ("Terms")  constitute  a  legally  binding  agreement  ("Agreement") between  you  and  Proppel  (as  defined  below)  governing  your  access  to  and  use  of  the Website,  including  any  subdomains  thereof,  and  any  other  websites  through  which  Proppel makes the Services available.
        </p>

        <h2>1.1 Definitions</h2>
        <p>In this Agreement, unless the context otherwise requires: </p>
        <p><strong>Advertisement</strong> means any advertisement provided by Proppel; </p>
        <p><strong>Advertising Materials</strong> means   artwork,   copy,   animations   or   any   active   URLs   for   the applicable Advertisements; </p>
        <p><strong>Agreement </strong> means  these  terms  and  conditions,  the  Proppel  privacy  policy  and  any  other terms and conditions incorporated by reference in these terms and conditions; </p>


        <p><strong>Confidential Information </strong> means any information</p>
        <ol type="a">
          <li>relating to the business of Proppel Pty Ltd, or the User;</li>
          <li>of Proppel or the User which is designated by the respective owner as confidential; or</li>
          <li>of Proppel or the User which is of a confidential or sensitive nature, which is marked or denoted  as  confidential  or  which  a  reasonable  person  to  whom  that  information  is disclosed  or  to  whose  knowledge  the  information  comes  would  consider  confidential, which  is  disclosed  by  Proppel  or  the  User  to  the  other,  directly  or  indirectly,  or otherwise comes to the knowledge of that party in relation to or in connection with this Agreement,  whether  that  information  is  in  oral,  visual  or  written  form  or  is  recorded  or embodied in any other medium;</li>
        </ol>
        <p><strong>GST</strong> has the meaning Goods and Services Tax Act 1999 (Cth);</p>
        <p><strong>Intellectual Property</strong> means  copyright,  all  rights  conferred  under  statute,  common  law  or equity  in  relation  to  inventions  (including  patents),  registered  and  unregistered  trade  marks, registered and unregistered designs, look and feel, circuit layouts and all other rights resulting from intellectual activity in, but not limited to, the industrial, scientific, literary or artistic fields; </p>
        <p><strong>Intellectual Property Rights</strong>
          means  any  and  all  intellectual  and  commercial  property  rights throughout  the  world  and  shall  include,  without  limitation,  copyright,  trade  marks,  designs, patents,  knowhow  and  trade  secrets,  whether  or  not  now  existing  and  whether  or  not registered  or  registrable  and  includes  applications  for  and  any  right  to  apply  for  the registration of such rights and includes all renewals and extensions;

        <p><strong>Lease Terms</strong>
          refers to the agreed terms of a the lease, the residential Tenancy agreement, between the Owner and Tenant as part of a Tenancy.
        </p>


        <p>
          <strong>Owner</strong> or <strong>Co-owner</strong> means Users of Proppel who offer Investment Property to Tenants. An Owner  User  is  someone  who  legally  owns  the  Property  or  has  the  authority  to  act  on  the Owner’s behalf.
        </p>

        <p>
          <strong>Policies</strong>
          means  Proppel's  policy  including  but  not  limited  to  content  limitations,  technical specifications,  privacy  policies,  advertising  or  editorial  policies,  which  can  be  accessed  at www.proppel.com.au
        </p>

        <p>
          <strong>Privacy Law</strong>
          means the Privacy Act 1988 (Cth) and any rules, guidelines, orders, directions, codes of conduct or other instruments made or issued under it;
        </p>

        <p>
          <strong>Property</strong>
          means the Australian Real Estate
        </p>

        <p>
          <strong>Property Materials</strong>
          means   all   general   information   details   photos   and   other   pertinent information requested by Proppel in displaying the property on the Website;
        </p>

        <p>
          <strong>Proppel</strong>
          means Proppel Pty Ltd ACN: 636 441 275
        </p>

        <p>
          <strong>Proppel Profile</strong>
          is a User’s profile on the Site following Registration.
        </p>

        <p>
          <strong>Publish or Published</strong>
          means the act of Proppel placing the Advertisement on the Website, where it can be viewed publicly;
        </p>

        <p>
          <strong>Registration </strong>
          means registering as a User of the Website or portal, subject to the terms and conditions.
        </p>

        <p>
          <strong>Services  </strong>
          means any associated services offered by Proppel used by Users to transact with Proppel Pty Ltd or other Users.
        </p>


        <p>
          <strong>Tenant  </strong>
          means a person who uses the Rental Property Services provided by an Owner.
        </p>

        <p>
          <strong>User </strong>
          means the User or subscriber for whom Proppel provides a tool to self-manage properties.
        </p>

        <p>
          <strong>Website </strong>
          mean the website portal operated by Proppel Pty Ltd at <a href="www.proppel.com.au">www.proppel.com.au</a>
        </p>

        <h2>1.2 Interpretation</h2>

        <p>Except to the extent that the context otherwise requires: </p>
        <ol type="a">
          <li>a reference to any legislation is a reference to that legislation as modified or re-enacted from time to time, and includes all regulations and statutory instruments issued under it;</li>
          <li>a reference to dollars or $ is to Australian currency; and </li>
          <li>if there is any inconsistency between the Terms, the Privacy Policy, a Request for Service or any other document or attachment incorporated by reference, to the extent of any such inconsistency, the order of priority will be:

            <ol type="I">
              <li>these Terms;</li>
              <li>the Privacy Policy;</li>
              <li>a Registration; </li>
              <li>any attachment or document incorporated by reference. </li>
            </ol>
          </li>
        </ol>



        <h2>2. Acceptance of Term </h2>
        <p>This Agreement Term will commence on the date of the  <strong>Registration</strong> by the User.</p>
        <ol type="a">
          <li>These terms are between you and Proppel </li>
          <li>the “accept” button or by accessing or using the product, you agree to be bound by these terms and our privacy policy (Proppel Portal > Menu > Proppel Policies > Proppel Privacy Policy). Our privacy policy is incorporated into these terms by reference and may be updated by us from time to time.</li>
          <li>We may revise these Terms from time to time, and we will endeavour to provide you with reasonable notice when we do so. Such notice may be displayed on the portal (or associated application).</li>
        </ol>

        <h2>3. Access to Service </h2>
        <ol type="a">
          <li>We grant you a limited, non-exclusive, non-transferrable right to access and use the Services strictly for personal property management purposes of the User strictly in accordance with these terms.</li>
          <li>In order to be able to access the Services, you must complete your registration details. You must ensure that your registration details are true and accurate at all times, and you must notify us of any change to your registration details as originally supplied.</li>
          <li>The user will create a username and password of authentication to gain access to the Services (Authentication Credentials).</li>
          <li>You must keep your Authentication Credentials confidential and private, and not disclose them to, or share them with, any other person. If you have lost or forgotten your Authentication Credentials, or you have reason to suspect any unauthorised use of them, you must immediately reset them within your Proppel Profile.</li>
          <li>We may from time to time and without notice, vary or modify, any or all of the Services, features or functions of Proppel.</li>
        </ol>

        <h2>4. Registration</h2>
        <ol type="a">
          <li>The User must submit a Registration to Proppel for access to the Website, Website or Portal.</li>
          <li>A Registration submitted by the User must confirm the following:
            <ol type="i">
              <li>Personal details of the User; </li>
              <li>Agreement with these Terms; </li>
              <li>Registration is deemed to have been accepted immediately upon the Registration being made via the Website. </li>
            </ol>
          </li>
        </ol>

        <h2>5. Restrictions </h2>
        <p>In using the Services, you must not:</p>
        <ol type="a">
          <li>provide inaccurate property ownership information or impersonate any person or entity;</li>
          <li>violate any applicable laws, or use the Services for any purpose that is unlawful;</li>
          <li>distribute viruses, spyware, malware, corrupted files, traps, time bombs, disabling code or any other similar software or programs that may damage the operation of any network, computer hardware or software;</li>
          <li>collect or store data about other users of the Services;</li>
        </ol>

        <h2>6. Payment </h2>
        <ol type="a">
          <li>The User shall pay Proppel at the rates stipulated by Proppel upon submission of a Registration (where fees are applicable).</li>
          <li>All rates payable are inclusive of GST. a. Proppel will submit an invoice to the User on an annual basis (if rates are applicable) and the User consents to payment of the invoice. </li>
          <li>Proppel will bind the invoice upon payment to a nominated property record as specified by user (if applicable).</li>
        </ol>
        <h2>7. Submission of Material to the Website </h2>
        <ol type="a">
          <li>Upon completion of a Registration the User shall be entitled to submit all Property Materials on the Proppel Portal.</li>
          <li>The User warrants that the Property Materials & Content:
            <ol type="i">
              <li>comply with the Terms and Privacy Policy; </li>
              <li>are not false or misleading;</li>
              <li>does not include any offensive material either written or in picture form;</li>
              <li>do not breach the Competition and Consumer Act 2010 (Cth) and/or any other applicable law; </li>
              <li>do not breach any third party Intellectual Property Rights; and</li>
              <li>do not breach any other contractual or proprietary rights of third parties.</li>
            </ol>
          </li>
          <li>Proppel may, in its reasonable opinion, reject or amend for publication or delete any Property Materials on the Website which do not comply with clause 6.</li>
          <li>The User bears sole responsibility for all content, including Property Materials, contained in any posting or submission to the Website and Proppel will not be held responsible for any data loss resulting from the use of any of our services, either directly or indirectly, intentional or as an unintentional consequence of our services, or the operation of the Website, or portal in general.
          </li>
        </ol>

        <h2>8. Intellectual property</h2>
        <ol type="a">
          <li>Each party acknowledges that the other party’s Intellectual Property (including Intellectual Property which is licensed to either party) is extremely important and valuable and is the property of the owning party.</li>
          <li>Each party acknowledges that it has no right, title or interest in or to the Intellectual Property of the other party.</li>
        </ol>

        <h2>9. Indemnities</h2>
        <ol type="a">
          <li>The User agrees to indemnify and keep Proppel indemnified from and against any damages, liabilities, claims, losses or expenses suffered or incurred, whether directly or indirectly, by Proppel as a result of any breach by the User of this Agreement or otherwise in connection with the Publishing of the Property Materials or non-Publishing of the Property Materials, as the case may be.
          </li>
        </ol>

        <h2>10. Termination</h2>
        <ol type="a">
          <li>The User may terminate this Agreement at any time but acknowledges and agrees that any payments made for the Term will not be refunded.</li>
          <li>Proppel may terminate this Agreement at any time should the User breach any term of this Agreement.</li>
        </ol>

        <h2>11. Notices</h2>
        <ol type="a">
          <li>All notices to be given under this Agreement are to be in writing addressed as follows:

            To the User: Via the email address provided by the User in their Registration To Proppel: admin@proppel.com.au
          </li>
          <li>All notices served under this Agreement will be considered to have been received immediately upon confirmation of the relevant email notice being sent.</li>
        </ol>

        <h2>12. Relationship </h2>
        <p>Nothing contained or implied in these Terms constitutes a party the partner or legal representative of the other party for any purpose, or creates any partnership, employment or trust. Neither party has the authority to bind the other party in any way.</p>

        <h2>13. Assignment </h2>
        <p>Neither party may assign its rights or obligations without the prior written consent of the other party.</p>

        <h2>14. Severability</h2>
        <p>If any part of this Agreement is held to be invalid or unenforceable in any way, the remaining provisions will not be affected and remain in full force.</p>

        <h2>15. Management Tool and Not Property Management </h2>
        <p>The User acknowledges and agrees that Proppel is providing a product (tool) for self-management of the User's Properties and is in not in any manner whatsoever managing the User's Properties and is not and will not be engaged as the property agent of any kind or a resident letting agent for the User as defined in the: </p>
        <ul>
          <li>Property Occupations Act 2014 (QLD) </li>
          <li>Property, Stock and Business Agents Act 2002 (NSW)  </li>
          <li>Agents Act 2003 (ACT) </li>
          <li>Agents Licensing Act (NT)</li>
          <li>Real Estate and Business Agents Act 1978 (WA) </li>
          <li>Land Agents Act 1994 (SA)</li>
          <li>Property Agents & Land Transactions Act 2005 (TAS)</li>
          <li>Residential Tenancies Act 1997 (VIC) </li>
        </ul>

        <h2>16. Governing law</h2>
        <p>These Terms will be governed by the laws of Victoria and the parties irrevocably submit to the non-exclusive jurisdiction of the courts of Victoria.</p>
        <h2>17. Support </h2>
        <ol type="a">
          <li>Whilst we endeavour to make the Services available at all times, your access to the Services may be affected by our network or equipment capacity, connection availability, scheduled or unscheduled downtime for maintenance or development purposes, interruptions which generally affect the internet. If interruptions or errors occur due to circumstances within our control, we will try to resolve them in a timely way in order to restore your access.</li>
          <li>If you experience any technical problems, you must make all reasonable efforts to investigate and diagnose such problems before contacting us using the provided tutorials within Proppel. If you still need technical help, please check the support provided in the Website, or failing that email us at support@proppel.com.au.</li>
          <li>We reserve the right to refuse to provide support services if:
            <ol type="I">
              <li>we consider that any support requests are frivolous, exceed reasonable volumes or are in respect of technical problems or other queries which we have already resolved;</li>
              <li>support requests relating to issues already covered by the support in the portal; or</li>
              <li>we consider that any support request is actually a request for specific training in connection with your use of the Services.</li>
              <li>We may suspend or cancel your access to the Services, either temporarily or permanently, with immediate effect by providing notice to you, if you are abusive or act improperly towards us or any of our directors, officers, employees or contractors in relation to your use of the Services or any support requested under this clause.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>









