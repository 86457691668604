import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyService} from "../../../../views/app/properties/property.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-property-list-tile-view',
  templateUrl: './property-list-tile-view.component.html',
  styleUrls: ['./property-list-tile-view.component.scss']
})
export class PropertyListTileViewComponent implements OnInit {
  optionsUpdates = {};
  @Input() rows: any[] = [];
  @Input() isSold: boolean = false;
  @Output() changeSortOrder = new EventEmitter();
  constructor(
    private propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  async drop(event: CdkDragDrop<string[]>, array: any[]) {
    // this.spinner.show();
    let sortArray:any [] =[]
    await moveItemInArray(array, event.previousIndex, event.currentIndex);

    await array.map((el, index) => {
      let data={
        "propertyRef":el.property_ref,
        "sort_order" : index
      };
      sortArray.push(data);
    });


    await this.propertyService.sortOrderProperty(sortArray).subscribe(response => {
      // this.spinner.hide();
      this.changeSortOrder.emit(true);
      // this.onSuccess('Sort Successful!');
    }, err => {
      this.globalService.onError(err.message);
      // this.spinner.hide();
    });
  }

}
