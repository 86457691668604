import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ListPropertiesComponent} from './views/app/applications/list-properties/list-properties.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./views/views.module').then(m => m.ViewsModule) },
  // { path: '', component: ListPropertiesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
