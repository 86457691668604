<ngx-spinner></ngx-spinner>
<div cdkDropList class="container col-12 col-md-12 col-lg-12" (cdkDropListDropped)="drop($event,rows)">
  <div *ngFor="let row of rows; let i=index" class="card mb-20 shadow property-list-card mb-4" [sortablejs]="rows"  [sortablejsOptions]="optionsUpdates"
       cdkDrag>
    <div class="custom-placeholder mb-20 mt-20" *cdkDragPlaceholder></div>
    <div class="col-12 pl-0 pr-0">
      <div class="property-card row">
        <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 pl-0 pr-0 pb-0">
          <div class="image-img-div">
<!--            <div class="watermark" *ngIf="isSold">-->
<!--              <img  class="img-fluid list-view-header-img-res-cover" [src]="row.headerImage" alt="Image" />-->
<!--              <img src="../../../../../assets/img/sold_out_1.png" alt="Watermark" class="watermark-image">-->
<!--            </div>-->
            <img  class="img-fluid list-view-header-img-res-cover" [src]="row.headerImage" alt="">
            <img *ngIf="row.sold == 1" class="sold-property" src="../../../../../assets/img/sold-banner.png">
          </div>
          <!--          <img src="assets/img/products/property_1.jpg" class="img-fluid">-->
        </div>
        <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 pl-0 pr-0" >
          <!--          <div class=" d-flex">-->
          <div class=" col-12 d-flex details-header justify-content-between mb-3">
            <h5 class="font-weight-bold mb-3">{{row.property_address}}</h5>
            <div class="d-flex">
              <h6 class="font-weight-bold">{{row.purpose}}</h6>
              <span class="badge badge-primary bg-primary mr-2 ml-2 pb-3" *ngIf="row.is_shared_user === true">Shared</span>
              <a href="javascript:void(0);"><i class="ml-2 fa fa-sliders f-18"></i></a>
            </div>
          </div>
          <!--          </div>-->
          <!--          <div class=" col-12 col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">-->
          <div class=" col-12 details-card row pr-0 ">
            <div class="col-12 col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div class="details d-flex justify-content-between text-dark">
                <p>Purchase Price</p>
                <p class="text-primary pl-1">${{ row.purchase_price | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Market Value</p>
                <p class="text-primary pl-1">${{ row.estimated_value_now | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Gain</p>
                <p class="text-primary pl-1">{{ row.capital_growth_percentage | number:'1.2-5' }}%</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Annual Rental Income</p>
                <p class="text-primary pl-1">${{ row.rent_per_month*12 | number:'1.2-5' }}</p>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div class="details d-flex justify-content-between text-dark">
                <p>Mortgage Balance</p>
                <p class="text-primary pl-1">${{ row.current_loan_balance | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Monthly Mortgage</p>
                <p class="text-primary pl-1">${{ row.monthly_repayments | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>LVR</p><p class="text-primary pl-1">{{ row.lvr | number:'1.2-5' }}%</p>
              </div>

            </div>
            <div class="col-12 col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div class="details d-flex justify-content-between text-dark">
                <p>Annual Rental Income</p><p class="text-primary pl-1">${{ row.rent_per_month*12 | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Monthly Income</p><p class="text-primary pl-1">${{ row.rent_per_month | number:'1.2-5' }}</p>
              </div>
              <div class="details d-flex justify-content-between text-dark">
                <p>Rental Yeid</p><p class="text-primary pl-1">{{ row.rental_yield | number:'1.2-5' }}%</p>
              </div>
            </div>
          </div>
          <!--          </div>-->
          <!--          <div class=" col-12 col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">-->
          <div class="property-list-details-section details-footer d-flex justify-content-between pl-2 pr-3">
            <div class="col-12 col-sl-3 col-lg-4 col-md-4 col-sm-12 pull-left">
              <div class="item-badge row pl-2 justify-content-between gap-2">
                <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bed"> {{row.bedrooms}}</i></span>
                <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bath"> {{row.bathrooms}}</i> </span>
                <span><i class="fa fa-car"></i> {{row.car_spaces}}</span>
                <ng-container *ngIf="row.property_type == 'Apartment' ||row.property_type == 'Unit' || row.property_type == 'Commercial'">
                  <span><i  class="fa fa-building-o"></i> {{row.building_size}}m<sup>2</sup></span>
                </ng-container>
                <ng-container *ngIf="row.property_type !== 'Apartment' && row.property_type !== 'Unit' && row.property_type != 'Commercial'">
                  <span> {{row.land_size}}m<sup>2</sup></span>
                </ng-container>
                <h6 class="font-weight-bold" style="padding-top: 8px;">{{row.property_type}}</h6>
              </div>
            </div>
            <div class="col-12 c0l-xl-9 col-lg-8 col-md-6 col-sm-12 pull-right button-list">
              <div class="details d-flex justify-content-between text-dark gap-2">
                <button class="btn btn-sm btn-outline-primary">New Rent</button>
                <button class="btn btn-sm btn-primary">New Expence</button>
                <button class="btn btn-sm btn-outline-primary">Copy Link</button>
                <button class="btn btn-sm btn-outline-primary"><i class="fa fa-files-o"></i></button>
              </div>
            </div>
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--</div>-->

