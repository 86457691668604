// import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
// const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  badge?: boolean;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'glyph-icon simple-icon-home',
    label: 'My Propreties',
    to: `/app/properties/list`,
    badge: true,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'glyph-icon simple-icon-list',
    label: 'List Propreties',
    to: `/app/properties`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-briefcase',
        label: 'Property Detail',
        to: `/app/properties/details`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-pie-chart',
        label: 'Rent',
        to: `/app/dashboards/analytics`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-basket-loaded',
        label: 'Mortgage',
        to: `/app/dashboards/ecommerce`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-event',
        label: 'Expenses',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-notebook',
        label: 'Documents',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-organization',
        label: 'Assets',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-puzzle',
        label: 'Issues',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-diamond',
        label: 'Insurances',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-picture',
        label: 'Pictures',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-rocket',
        label: 'Suppliers',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-note',
        label: 'To-do List',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
      {
        icon: 'simple-icon-info',
        label: 'Other Info',
        to: `/app/dashboards/content`,
        // roles: [UserRole.Admin, UserRole.Editor],
      },
    ],
  },
  {
    icon: 'iconsminds-calculator',
    label: 'Calculator',
    to: `/app/pages`,
    subs: [
      {
        icon: 'simple-icon-user-following',
        label: 'menu.login',
        to: '/user/login',
        newWindow: true,
      },
      {
        icon: 'simple-icon-user-follow',
        label: 'menu.register',
        to: '/user/register',
        newWindow: true,
      },
      {
        icon: 'simple-icon-user-unfollow',
        label: 'menu.forgot-password',
        to: '/user/forgot-password',
        newWindow: true,
      },
      {
        icon: 'simple-icon-user-following',
        label: 'menu.reset-password',
        to: '/user/reset-password',
        newWindow: true,
      },
    ],
  },
  {
    icon: 'iconsminds-repeat-2',
    label: 'Comparisons',
    to: `/app/applications`,
    subs: []
  },
  {
    icon: 'iconsminds-pantone',
    label: 'Knowledge',
    to: `/app/ui`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'Knowledge',
        to: `/app/ui/forms`,
        subs: [],
      },
      {
        icon: 'simple-icon-organization',
        label: 'Learn Proppel',
        to: `/app/ui/forms`,
        subs: [],
      },
    ],
  },
  {
    icon: 'glyph-icon simple-icon-grid',
    label: 'My Number',
    to: `/app/blank-page`,
    subs: []
  }
];
export default data;

