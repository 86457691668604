import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnauthorisedUserInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>,
            next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloneRequest =  request.clone({
          headers: request.headers.set('X-Requested-With', 'XMLHttpRequest'),
          withCredentials: true,
        })
        return next.handle(request).pipe( tap(() => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              this.router.navigate(['/user/login']);
              
              // console.log(err.status);
            }
          }));
  }
}
