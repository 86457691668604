import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import {ResponseBodyChangeInterceptorService} from "./interceptors/response-body-change-interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {UserRefResponseBodyInterceptor} from "./interceptors/user-ref-response-body.interceptor";
import { PropertyListViewComponent } from './componants/property/property-list-view/property-list-view.component';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SortablejsModule} from "ngx-sortablejs";
import {NgxSpinnerModule} from "ngx-spinner";
import { PropertyListTileViewComponent } from './componants/property/property-list-tile-view/property-list-tile-view.component';
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {UnauthorisedUserInterceptorService} from "./interceptors/unauthorised-user-interceptor.service ";
@NgModule({
  declarations: [ErrorComponent, UnauthorizedComponent, PropertyListViewComponent, PropertyListTileViewComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    BsDropdownModule,
    DragDropModule,
    SortablejsModule,
    NgxSpinnerModule,
    CommonModule,
    CommonModule,
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    PropertyListViewComponent,
    PropertyListTileViewComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ResponseBodyChangeInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UserRefResponseBodyInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorisedUserInterceptorService, multi: true},
  ],
})
export class SharedModule {}
