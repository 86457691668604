<div cdkDropList class="row" (cdkDropListDropped)="drop($event,rows)">
  <div *ngFor="let row of rows; let i=index" class="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-4 mb-4" cdkDrag>
    <!--      <div class="custom-placeholder mb-20 mt-20" *cdkDragPlaceholder></div>-->
    <div class="property-card" (click)="navigateTo(row)">
      <div class="image-img-div">
        <ng-container *ngIf="row.sold == 0">
        <img class="img-fluid img-res-cover" [src]="row.headerImage" alt="">
        </ng-container>
        <ng-container *ngIf="row.sold == 1">
                      <div class="watermark" *ngIf="isSold">
                        <img  class="img-fluid list-view-header-img-res-cover" [src]="row.headerImage" alt="Image" />
                        <img src="../../../../../assets/img/sold_out_1.png" alt="Watermark" class="watermark-image">
                      </div>
        </ng-container>
      </div>
      <div class="property-card-body ">
        <div class="item-badge d-flex justify-content-between mb-2">
          <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bed"> {{row.bedrooms}}</i></span>
          <span ><i [hidden]="row.purpose == 'Land Bank'" class="fa fa-bath"> {{row.bathrooms}}</i> </span>
          <span><i class="fa fa-car"></i> {{row.car_spaces}}</span>
          <ng-container *ngIf="row.property_type == 'Apartment' ||row.property_type == 'Unit' || row.property_type == 'Commercial'">
            <span><i  class="fa fa-building-o"></i> {{row.building_size}}m<sup>2</sup></span>
          </ng-container>
          <ng-container *ngIf="row.property_type !== 'Apartment' && row.property_type !== 'Unit' && row.property_type != 'Commercial'">
            <span> {{row.land_size}}m<sup>2</sup></span>
          </ng-container>
        </div>

        <h5 class="font-weight-bold mb-2">{{row.property_address}}</h5>
        <h5 class="font-weight-bold mb-3">{{row.purpose}}</h5>
        <div class="details d-flex justify-content-between text-dark">
          <p>Purchase Price</p><p class="text-primary">${{ row.purchase_price | number:'1.2-5' }}</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Market Value</p><p class="text-primary">${{ row.estimated_value_now | number:'1.2-5' }}</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Gain</p><p class="text-primary">{{ row.capital_growth_percentage | number:'1.2-5' }}%</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Mortgage Balance</p><p class="text-primary">${{ row.current_loan_balance | number:'1.2-5' }}</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Monthly Mortgage</p><p class="text-primary">${{ row.monthly_repayments | number:'1.2-5' }}</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>LVR</p><p class="text-primary">{{ row.lvr | number:'1.2-5' }}%</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Annual Rental Income</p><p class="text-primary">${{ row.rent_per_month*12 | number:'1.2-5' }}</p>
        </div>

        <div class="details d-flex justify-content-between text-dark">
          <p>Monthly Income</p><p class="text-primary">${{ row.rent_per_month | number:'1.2-5' }}</p>
        </div>
        <div class="details d-flex justify-content-between text-dark">
          <p>Rental Yield</p><p class="text-primary"> {{ row.rental_yield | number:'1.2-5' }}%</p>
        </div>
        <div class="details d-flex justify-content-between text-dark mt-3">
          <button class="btn btn-outline-primary btn-xs">New Rent</button>
          <button class="btn btn-primary">New Expence</button>
          <button class="btn btn-outline-primary">New Mortgage</button>
          <a href="javascript:void(0);" class="ft-18 mt-1 p-1"><i class="fa fa-files-o"></i></a>
          <a href="javascript:void(0);" class="ft-18 mt-1 p-1"><i class="fa fa-sliders"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
