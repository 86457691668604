import {Injectable, Inject, Output, EventEmitter, Component} from "@angular/core";
import {API_BASE_URL, PLACES_API_KEY, RE_CAPTCHA_KEY,IS_PRODUCTION,IS_MAINTENANCE} from "./config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
// import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { CommunicationService } from "./communication.service";
import jwt_decode from "jwt-decode";
import value from "*.json";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import { FormBuilder } from "@angular/forms";
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";


@Injectable({
  providedIn: "root",
})
export class GlobalService {

  @Output() notificationEvent: EventEmitter<string> = new EventEmitter();
  @Output() successNotificationFireEvent: EventEmitter<string> = new EventEmitter();
  // @Output() userPermission: EventEmitter<string> = new EventEmitter();
  @Output() propertyPermissionEvent: EventEmitter<string> = new EventEmitter();
  @Output() propertyMenuLoadingEvent: EventEmitter<string> = new EventEmitter();
  @Output() profilePictureChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() userNameChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() userPlanTypeChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() public reportNewIssueEvent:EventEmitter<string> = new EventEmitter();
  @Output() public closeMainModalEvent:EventEmitter<string> = new EventEmitter();
  @Output() propertyListTureEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() openExpenseViewModelForExpensePaymentEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() openPropertyValueModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() demoUserEvent: EventEmitter<any> = new EventEmitter();
  @Output() openUserDetailsModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openNewInDevelopmentModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openNewInDevelopmentContentModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openEOFYReportModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeExpenseForEofyModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() parentModelSwitchEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertyPurposeModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closePropertyPurposeModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() propertyRegistrationModelChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() addNewPropertyModelPopUpEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadPropertyDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadPropertyImageViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() propertyImageViewModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() isFirstTimeLoginChangeEvent: EventEmitter<any> = new EventEmitter();

  @Output() openKnowledgeModalEvent: EventEmitter<any> = new EventEmitter();

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();
  private MethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();
  MethodCalled$ = this.MethodCallSource.asObservable();

  public lgModalOption: NgbModalOptions = {
    size: "lg",
    centered: true,
    backdrop: "static",
    keyboard: false,
  };
  public smModalOption: NgbModalOptions = {
    size: "sm",
    centered: true,
    backdrop: "static",
    keyboard: false,
  };
  public defaultModalOption: NgbModalOptions = {
    centered: true,
    backdrop: "static",
    keyboard: false,
  };

  apiURL: string;
  places_api_key: string;
  apiUrl:string;
  re_captcha_key:string;
  is_production:any;
  isOpen = false;
  modalParams: any = null;
  is_maintenance: boolean = false;
  constructor(
    private http: HttpClient, private communicationService: CommunicationService,private notifications: NotificationsService,private translate: TranslateService,
    // @Inject(API_BASE_URL) apiUrl: string,
    @Inject(PLACES_API_KEY) places_api_key: string,
    @Inject(API_BASE_URL) apiUrl: string,
    @Inject(RE_CAPTCHA_KEY) recaptchaKey: string,
    @Inject(IS_PRODUCTION) is_production: any,
    @Inject(IS_MAINTENANCE) is_maintenance: string,
    private router: Router,
  ) {
    // this.apiURL = environment.apiUrl;
    this.apiURL = apiUrl;
    this.places_api_key = places_api_key;
    this.re_captcha_key = recaptchaKey;
    //convert string to boolean
    this.is_maintenance = (is_maintenance.toLowerCase() =="true");
    this.is_production = JSON.parse(is_production);
  }

  permissionChange(permissions:  any) {
    this.propertyPermissionEvent.emit(permissions);
  }

  menuLoadingEvent(value){
    this.propertyMenuLoadingEvent.emit(value)
  }

  getAPIUrl() {
    return this.apiURL;
  }

  getAPIKey() {
    return this.places_api_key;
  }

  getReCaptchaKey() {
    return this.re_captcha_key;
  }

  getIsProduction() {
    return this.is_production;
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
  }

  getHttpOptionsGoogleAPI() {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "http://localhost:4200",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
  }
  getHttpOptionsAgentAPI() {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "http://localhost:4200",
        "x-api-key": "key_799d168226cbd532526829f657b429fb",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
  }

  getHttpOptionsBlob() {
    return {
      responseType: "blob" as "json",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
  }

  commonFileUploader(data){
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/common-file-upload",
      data,
      httpOption
    );
  }

  uploadFile(data, userRef, rentalRef:any = 'not' , uploadFor:any = null) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "upload/file/" + userRef + "?rental_ref=" + rentalRef + "&upload_for=" + uploadFor,
      data,
      httpOption
    );
  }

  rentalUploadFile(data) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/rental-file-upload",
      data,
      httpOption
    );
  }

  mortgageUploadFile(data) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/mortgage-file-upload",
      data,
      httpOption
    );
  }

  splitMortgageUploadFile(data) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/split-mortgage-file-upload",
      data,
      httpOption
    );
  }

  expenseUploadFile(data) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/expense-file-upload",
      data,
      httpOption
    );
  }

  // uploadDocuments(data, userRef, propertyRef:any = null ) {
  //   let httpOption = {
  //     headers: new HttpHeaders({
  //       Authorization: "Bearer " + localStorage.getItem("authToken"),
  //     }),
  //   };
  //   return this.http.post(
  //     this.apiURL + "upload/documents/" + userRef + "?property_ref=" + propertyRef,
  //     data,
  //     httpOption
  //   );
  // }

  uploadRentExcelFile(data: File[], userRef , rentalRef) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "upload/rentExcelFile/" + userRef +"/"+ rentalRef,
      data,
      httpOption
    );
  }

  uploadFilePdfReview(data: File[], userRef, documentFolderRef, propertyRef) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL +
      "upload/fileReview/" +
      userRef +
      "/" +
      documentFolderRef +
      "/" +
      propertyRef,
      data,
      httpOption
    );
  }

  uploadPdfFileToImages(data: File[], propertyRef) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/pdf-file-upload?property_ref="+ propertyRef,
      data,
      httpOption
    );
  }

  unauthorizedRequest(status) {
    if (status == 401) {
      localStorage.setItem("authToken", "");
      localStorage.clear();
      this.router.navigate(["/auth/login/"]);
    }
  }

  calculateMortgage(loanTerm, interestRate, loanType, loanAmount) {
    let term_months = 0;
    term_months = loanTerm * 12;
    let monthly_rate = 0.0;
    monthly_rate = interestRate / 12 / 100;
    let exponent = 0;
    exponent = Math.pow(monthly_rate + 1.0, term_months);
    let monnthly_payment = 0;
    if (loanType == "0") {
      monnthly_payment = Math.ceil(loanAmount * monthly_rate);
    }
    if (loanType == "1") {
      monnthly_payment = Math.ceil(
        (loanAmount * monthly_rate * exponent) / (exponent - 1.0)
      );
    }
    return monnthly_payment;
  }

  updatePropertyCount(property_count) {
    localStorage.setItem("property_count", property_count);
    this.componentMethodCallSource.next(property_count);
  }

  verifyInterestRate() {
    return this.http.get(
      this.getAPIUrl() + "verify_interest_rate",
      this.getHttpOptions()
    );
  }

  notificationChange(count) {
    this.notificationEvent.emit(count);
  }

  getUserPermissions(permission){
    this.MethodCallSource.next(permission);
  }

  formNavigation(event: MouseEvent, url) {
    if (this.communicationService.form_dirty_status) {
      if (!confirm('There are unsaved changes in the form. Are you sure to move away?')) {
        event.preventDefault();
        return false;
      } else {
        this.communicationService.form_dirty_status = false;
        this.communicationService.check_form_dirty = false;
        this.router.navigate([url]);
      }
    }
    else {
      this.router.navigate([url]);
    }
  }

  getUser() {
    if (localStorage.getItem('authToken')) {
      let token = localStorage.getItem('authToken');
      let decoded = jwt_decode(token);
      return decoded['user'];
    }
    return null;
  }

  getPropertyCount() {
    if (localStorage.getItem('authToken')) {
      let token = localStorage.getItem('authToken');
      let decoded = jwt_decode(token);
      return decoded['property_count'];
    }
    return null;
  }
  // getPropCount(propertyCount){
  //   return propertyCount;
  // }

  mediaDownload(data){
    return this.http.get(this.getAPIUrl() + 'file/get-file-download?media_ref='+data['media_ref'], this.getHttpOptionsBlob());
  }

  successNotification(message){
    this.successNotificationFireEvent.emit(message)
  }

  ErrorNotification(message) {
    // this.notifications.create(this.translate.instant('alert.error'), message, NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  getLearningArticleList(data) {
    return this.http.post(this.getAPIUrl() + 'client/learning-article/get-learning-article-list' ,data, this.getHttpOptions());
  }

  profileMediaUpload(data, type: any,purpose) {
    // let formData = new FormData();
    // formData.append['file'] = data;
    // formData.append['type'] = type;
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(
      this.apiURL + "file/profile-media-upload?upload_type="+ type + '&purpose='+purpose,
      data,
      httpOption
    );
  }

  profilePictureChange(thumb){
    this.profilePictureChangeEvent.emit(thumb);
  }

  userNameChange(name){
    this.userNameChangeEvent.emit(name);
  }

  getArticleDetails(form_id) {
    return this.http.get(this.getAPIUrl() + 'client/learning-article/get-learning-article-list-by-form-id?form_id='+form_id, this.getHttpOptions());
  }

  userPlanTypeChange(planType){
    this.userPlanTypeChangeEvent.emit(planType) ;
  }

  validateNickName(nickName) {
    return this.http.post(this.getAPIUrl() + 'auth/validate-user-name',nickName, this.getHttpOptions());
  }

  validateEmail(data) {
    return this.http.post(this.getAPIUrl() + 'auth/validate-user-email', data, this.getHttpOptions());
  }


  reportNewIssueModal(data) {
    this.reportNewIssueEvent.emit(data);
  }

  openKnowledgeModal(data) {
    this.openKnowledgeModalEvent.emit(data);
  }

  closeModel(data){
    this.closeMainModalEvent.emit(data)
  }

  saveTourSettings(tourData: any) {
    return this.http.post(this.getAPIUrl() + 'user/save-property-settings' ,tourData, this.getHttpOptions());
  }

  startPropertyListTure(data:boolean){
    this.propertyListTureEvent.emit(data);
  }

  getSplitMortgageDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-split-mortgage-details', data,this.getHttpOptions());
  }


  getMortgageHeaderDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-mortgage-header-and-split-data', data,this.getHttpOptions());
  }


  getMortgageHeaderFormData(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-mortgage-header-form-data', data, this.getHttpOptions());
  }

  savePropertyMortgageHeaderDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/save-property-mortgage-header-details', data,this.getHttpOptions());
  }

  getMortgageDataByMortgageRef(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-mortgage-data-by-mortgage-ref', data, this.getHttpOptions());
  }

  showMessage(data){
    return this.http.post(this.getAPIUrl() + 'property/update-message', data, this.getHttpOptions());
  }

  createMissingVariableMortgage(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/create-missing-variable-mortgage', data, this.getHttpOptions());
  }

  saveSplitMortgageDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/save-split-mortgage-details', data, this.getHttpOptions());
  }

  getMortgageHeaderAndMortgageDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-mortgage-header-and-mortgage-details', data,this.getHttpOptions());
  }

  saveMortgageDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/save-mortgage-details', data, this.getHttpOptions());
  }

  getExpenseFormData(property_ref) {
    return this.http.get(this.getAPIUrl() + 'property/expense/get-expense-form-data?property_ref='+property_ref, this.getHttpOptions());
  }

  openExpenseViewModelForExpensePayment(data) {
    this.openExpenseViewModelForExpensePaymentEvent.emit(data);
  }

  saveNewExpenseCategory(data) {
    return this.http.post(this.getAPIUrl() + 'property/expense/save-expense-category', data, this.getHttpOptions());
  }

  deleteExpenseCategory(category_id) {
    return this.http.get(this.getAPIUrl() + 'property/expense/delete-expense-categories?expense_category_id=' + category_id, this.getHttpOptions());
  }

  saveNewExpensePayment(data) {
    return this.http.post(this.getAPIUrl() + 'property/expense/save-new-expense-payment', data, this.getHttpOptions());
  }

  saveSplitMortgage(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/save-split-mortgage-header-details', data, this.getHttpOptions());
  }

  validateNumberFormat(value){
    console.log(value);
    if(value != null || value != undefined || value != ''){
      let regexp = new RegExp('^(\\d{1,5}|\\d{0,5}\\.\\d{1,2})$');
      let result =  regexp.test(value);
      let resultValue =  value;
      if(!result){
        resultValue = value.toFixed(2);
      }
      return resultValue;
    }
    return  0;
  }

  lookupSupplierFilter(data) {
    return this.http.post(this.getAPIUrl() + 'property/supplier/lookup-supplier-filter', data, this.getHttpOptions());
  }

  getSupplierTypes(property_ref) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/get-supplier-types?property_ref='+property_ref, this.getHttpOptions());
  }

  saveSupplierType(data) {
    return this.http.post(this.getAPIUrl() + 'client/supplier/save-supplier-type' ,data, this.getHttpOptions());
  }

  checkSupplierType(supplier_type_id, propertyRef) {
    return this.http.get(this.getAPIUrl() + 'client/supplier/check-supplier-type?supplier_type_id=' + supplier_type_id + '&property_ref=' + propertyRef, this.getHttpOptions());
  }

  deleteSupplierType(supplier_type_id: any, propertyRef: string) {
    return this.http.get(this.getAPIUrl() + 'client/supplier/delete-supplier-type?supplier_type_id=' + supplier_type_id + '&property_ref=' + propertyRef, this.getHttpOptions());
  }

  saveSupplierDetails(data) {
    return this.http.post(this.getAPIUrl() + 'client/supplier/save-supplier-details', data, this.getHttpOptions());

  }


  getSupplierList(agent_data) {
    return this.http.post(this.getAPIUrl() + 'property/supplier/get-supplier-list', agent_data, this.getHttpOptions());
  }

  searchSupplierByLocation(data) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/search-location?searchKey='+ data, this.getHttpOptions());
  }

  getInsurerList(supplier_data) {
    return this.http.post(this.getAPIUrl() + 'property/insurance/get-insurer-list', supplier_data, this.getHttpOptions());
  }

  getSuppliersForMortgage(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/get-suppliers-for-mortgage', data, this.getHttpOptions());
  }

  getSuppliersDetailsByRef(supplier_ref,property_ref) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/get-supplier-details-by-ref?supplier_ref='+ supplier_ref + '&property_ref='+property_ref, this.getHttpOptions());
  }

  editSupplierDetails(data) {
    return this.http.post(this.getAPIUrl() + 'property/supplier/edit-supplier-details', data, this.getHttpOptions());
  }

  verifySupplierCompanyName(valueElement) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/verify-supplier-company-name?company_name='+ valueElement, this.getHttpOptions());
  }

  searchAgent(searchKey) {
    return this.http.post(this.getAPIUrl() + 'property/rental/search-agent' , searchKey, this.getHttpOptions());
  }

  getSuppliers(searchKey: any) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/get-search-suppliers?search_key=' + searchKey + '&supplier_type=', this.getHttpOptions());
  }

  addSupplierToProperty(data) {
    return this.http.post(this.getAPIUrl() + 'property/supplier/add-supplier-to-property', data, this.getHttpOptions());
  }

  getIssuesForms() {
    return this.http.get(this.getAPIUrl() + 'client/proppel-issues/get-issues-forms', this.getHttpOptions());
  }

  checkIsDemoUser(data) {
    this.demoUserEvent.emit(data);
  }

  openUserDetailsModel(data) {
    this.openUserDetailsModelEvent.emit(data);
  }

  saveDemoUserData(value: any) {
    return this.http.post(this.getAPIUrl() + 'user/save-demo-user-data',value, this.getHttpOptions());
  }

  activateProperty(data: { property_list: any }) {
    return this.http.post(this.getAPIUrl() + 'property/activate-property', data, this.getHttpOptions());
  }

  closeDemoAccount() {
    return this.http.get(this.getAPIUrl() + 'user/close-demo-account', this.getHttpOptions());
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  openNewInDevelopmentModel() {
    this.openNewInDevelopmentModelEvent.emit();
  }

  openNewInDevelopmentContentModel(data) {
    this.openNewInDevelopmentContentModelEvent.emit(data);
  }

  getNewInDevelopmentList() {
    return this.http.get(this.getAPIUrl() + 'new-development/get-new-in-development', this.getHttpOptions());
  }

  openEOFYReportModel(){
    this.openEOFYReportModelEvent.emit();
  }

  getEOFYReports(formData){
    return this.http.post(this.getAPIUrl() + 'eofy-report/get-eofy-report-list',formData, this.getHttpOptions());
  }

  getYearRange(){
    return this.http.get(this.getAPIUrl() + 'eofy-report/get-year-range', this.getHttpOptions());
  }

  getPropertyListForEOFYReport(data:any){
    return this.http.post(this.getAPIUrl() + 'eofy-report/get-property-list-for-eofy-report',data, this.getHttpOptions());
  }

  getPropertyListYearRangeForEOFYReport(){
    return this.http.get(this.getAPIUrl() + 'eofy-report/get-property-list-year-range-for-eofy-report', this.getHttpOptions());
  }

  getPropertyListForEOFYReportByYear(data:any){
    return this.http.post(this.getAPIUrl() + 'eofy-report/get-property-list-for-eofy-report-by-year', data, this.getHttpOptions());
  }

  getExpensesForEofy(property_ref: any, financial_year: any) {
    return this.http.get(this.getAPIUrl() + 'eofy-report/get-expenses-for-eofy?property_ref=' + property_ref + '&financial_year=' + financial_year, this.getHttpOptions());
  }


  getLastExpenseDetail(details) {
    return this.http.post(this.getAPIUrl() + 'property/expense/get-last-expense-detail', details, this.getHttpOptions());
  }

  deleteEofyData(eofy_report_ref) {
    return this.http.get(this.getAPIUrl() + 'eofy-report/delete-eofy-data?eofy_report_ref='+eofy_report_ref, this.getHttpOptions());
  }

  addExpenseDetailSubmitForEofy(data) {
    return this.http.post(this.getAPIUrl() + 'property/expense/add-expense-detail-submit-for-eofy', data, this.getHttpOptions());
  }

  closeExpenseForEofyModal(data) {
    this.closeExpenseForEofyModalEvent.emit(data);
  }

  deleteExpenseEofy(data) {
    return this.http.post(this.getAPIUrl() + 'property/expense/delete-expense-eofy', data, this.getHttpOptions());
  }

  updatePurchaseSettlementDate(data) {
    return this.http.post(this.getAPIUrl() + 'property/update-purchase-settlement-date', data, this.getHttpOptions());
  }

  updatePurchasePrice(data) {
    return this.http.post(this.getAPIUrl() + 'property/update-purchase-price', data, this.getHttpOptions());
  }

  updateCapitalImprovement(data: any) {
    return this.http.post(this.getAPIUrl() + 'property/update-capital-improvement', data, this.getHttpOptions());
  }


  getOwnerList(data: any) {
    return this.http.get(this.getAPIUrl() + 'property/get-owner-list?keyword='+data,  this.getHttpOptions());
  }

  loadSupportedExtensions() {
    return   this.http.get<any>('assets/fileForamts.json');
  }

  sendMessage(value) {
    return this.http.post(this.getAPIUrl() + 'message/send-message', value, this.getHttpOptions());
  }

  downloadSelectedDocuments(selectedDocumentIds: any, zipName: any) {
    return this.http.post(this.getAPIUrl() + 'file/document-download-zip', {selectedDocumentIds, zipName}, this.getHttpOptionsBlob());
  }

  updateContractOfSaleDate(data) {
    return this.http.post(this.getAPIUrl() + 'property/update-contract-of-sale-date', data, this.getHttpOptions());
  }

    getNameOnTitleList() {
        return this.http.get(this.getAPIUrl() + 'eofy-report/get-nameontitle-eofy-report', this.getHttpOptions());
    }

  parentModelSwitch(data) {
    this.parentModelSwitchEvent.emit(data);
  }

  openPropertyPurposeModel(data) {
    this.openPropertyPurposeModelEvent.emit(data);
  }

  closePropertyPurposeModel(data) {
    this.closePropertyPurposeModelEvent.emit(data);
  }

  propertyRegistrationModelChange(data) {
    this.propertyRegistrationModelChangeEvent.emit(data)
  }

  addNewPropertyModelPopUp(data) {
    this.addNewPropertyModelPopUpEvent.emit(data)
  }

  loadPropertyDetails(data){
    this.loadPropertyDetailsEvent.emit(data);
  }

  switchClient(data) {
    return this.http.post(this.apiURL + 'admin-auth/view-as-client' , data, this.getHttpOptions());
  }

  getMortgageDetailsForPropertyRegistration(propertyRef: string) {
    return this.http.get(this.getAPIUrl() + 'property/mortgage/get-mortgage-details-for-property-registration?property_ref='+propertyRef, this.getHttpOptions());
  }

  loadPropertyImageView(data) {
    this.loadPropertyImageViewEvent.emit(data);
  }

    propertyImageViewModelClose(data) {
        this.propertyImageViewModelCloseEvent.emit(data);
    }

  bannerTouch(data) {
    return this.http.post(this.getAPIUrl() + 'user/banner-update', data, this.getHttpOptions());
  }

  randomStringGenerator() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let text = "";
    for (let i = 0; i < 4; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  searchSupplierType(value) {
    return this.http.get(this.getAPIUrl() + 'property/supplier/search-supplier-type?search_key='+value, this.getHttpOptions());
  }

  getSupplierCreateFormInitData(param: any) {
    return this.http.post(this.getAPIUrl() + 'property/supplier/get-supplier-create-form-init-data',param, this.getHttpOptions());
  }

  saveNoMortgage(data) {
    return this.http.post(this.getAPIUrl() + 'property/mortgage/save-no-mortgage', data, this.getHttpOptions());
  }

  registerProperty(propertyRef: string) {
    return this.http.get(this.getAPIUrl() + 'property/register-property?property_ref='+propertyRef, this.getHttpOptions());
  }

  isFirstTimeLoginChange(data) {
    this.isFirstTimeLoginChangeEvent.emit(data);
  }

  getPropertyForSignup() {
    return this.http.get(this.getAPIUrl() + 'property/get-property-for-signup', this.getHttpOptions());
  }

  getTodayDate() {
    //get today date in YYYY-MM-DD format
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
     return yyyy+'-'+mm+'-'+dd;
  }

  openModal(params: any = null) {
    console.log('fs')
    this.isOpen = true;
    this.modalParams = params;
  }

  closeModal() {
    this.isOpen = false;
    this.modalParams = null;
  }
}
