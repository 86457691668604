import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  deviceInfo = null;
  isMobile = false;
  isTablet = false;
  isDesktopDevice = false;
  constructor(private langService: LangService,
              private renderer: Renderer2,
              private deviceService: DeviceDetectorService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.langService.init();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();


    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const resetPasswordRegex = /user\/reset-password\/(.*)/;
        const emailVerifyRegex = /user\/email-verify\/(.*)/;
        const url = e.url;

        if (resetPasswordRegex.test(url) || emailVerifyRegex.test(url)) {
          // The current URL is a reset password or email verify URL.
        } else {
          const isUnsupportedBrowser = this.isMobile || this.isTablet || this.isUnsupportedBrowser();

          if (isUnsupportedBrowser) {
         //   this.router.navigateByUrl('/browser/compatibility');
          }
        }
      }
    });
  }

  private isUnsupportedBrowser(): boolean {
    return this.deviceInfo.browser === 'Internet Explorer' || this.deviceInfo.browser === 'Safari';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
}
